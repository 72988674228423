// extracted by mini-css-extract-plugin
export var benefitsCustomSoftwareSection = "M_hy";
export var businessCustomSoftwareSection = "M_hw";
export var caseStudyCustomSoftwareSection = "M_hB";
export var casesCustomSoftwareSection = "M_hD";
export var clientQuotesCustomSoftwareSection = "M_hM";
export var customSoftwareProjLogoSection = "M_hG";
export var expertiseCustomSoftwareSection = "M_hH";
export var industriesCustomSoftwareSection = "M_hv";
export var ourAchievementsCustomSoftwareSection = "M_hL";
export var processCustomSoftwareSection = "M_hx";
export var requirementCustomSoftwareSection = "M_hC";
export var servicesCustomSoftwareSection = "M_ht";
export var techStackCustomSoftwareSection = "M_hz";
export var testimonialsCustomSoftwareSection = "M_hJ";
export var testimonialsCustomSoftwareSection__testimonial = "M_hK";
export var whyChooseCustomSoftwareSection = "M_hF";